import { Application } from "@hotwired/stimulus"
import { registerControllers } from 'stimulus-vite-helpers' // automatically load controller files https://github.com/ElMassimo/stimulus-vite-helpers

const application = Application.start()
const controllers = import.meta.glob('./**/*_controller.js', { eager: true })

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

registerControllers(application, controllers)

// https://github.com/excid3/tailwindcss-stimulus-components
import { Dropdown } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)